import React, { memo } from "react";
import { Stack, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "@emotion/styled";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface FilterSectionProps {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    onStartDateChange: (date: Dayjs | null) => void;
    onEndDateChange: (date: Dayjs | null) => void;
    onClear: () => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    onClear,
}) => {
    const { t } = useTranslation();

    return (
        <StyledFilterContainer>
            <StyledDatePicker
                label={t("select.date.start")}
                value={startDate ?? null}
                onChange={(value: any) => onStartDateChange(value ?? null)}
                format="DD/MM/YYYY"
                timezone="UTC"
            />
            <StyledDatePicker
                label={t("select.date.end")}
                value={endDate ?? null}
                onChange={(value: any) => onEndDateChange(value ?? null)}
                format="DD/MM/YYYY"
                timezone="UTC"
            />

            <StyledFilterButton variant="outlined" onClick={onClear}>
                {t("mission.dashboard.clear")}
            </StyledFilterButton>
        </StyledFilterContainer>
    );
};

export default memo(FilterSection);

const StyledFilterContainer = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    marginBottom: "20px",
    "@media (min-width: 768px)": {
        flexDirection: "row",
    },
});

const StyledDatePicker = styled(DatePicker)({
    width: "100%",
    "@media (min-width: 768px)": {
        width: "fit-content",
    },
});

const StyledFilterButton = styled(Button)({
    width: "100%",
    marginLeft: "auto",
    "@media (min-width: 768px)": {
        width: "fit-content",
    },
});
