import { useEffect, useState } from "react";
import { getProjectsById, getProjectsByIdPro } from "./redux/projectAction";
import { RootState } from "../common/redux/store";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { StructureForm } from "../projects/StructureForm";
import { ProfessionnelForm } from "../projects/ProfessionnelForm";
import { StructureCalendar } from "../scheduler/StructureCalendar";
import { ProfessionnelCalendar } from "../scheduler/ProfessionnelCalendar";

export const ProjectPage = () => {
    const userSelector = useAppSelector((state: RootState) => state.user.data);
    const userProData = useAppSelector(
        (state: RootState) => state.userPro.data
    );
    const projects = useAppSelector((state: RootState) => state.projects.data);
    const [, setAccount] = useState(userSelector);
    const [, setIsDisplayPopUpCreateProject] = useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (userSelector.societe) {
            dispatch(getProjectsById(userSelector.id));
            setAccount(userSelector.societe);
        } else {
            dispatch(getProjectsByIdPro(userProData.id));
            setAccount(userProData.name);
        }
    }, [dispatch, userSelector, userProData]);

    useEffect(() => {
        if (userSelector.isCreateProject || userProData.isCreateProject) {
            setIsDisplayPopUpCreateProject(true);
        }
    }, [userSelector.isCreateProject, userProData.isCreateProject]);

    const numberOfProjects = () => {
        let totalProjects = 0;

        if (userSelector.societe) {
            totalProjects += Array.isArray(userSelector.disponibilities)
                ? userSelector.disponibilities.length
                : 0;
        } else {
            totalProjects += Array.isArray(userProData.disponibilities)
                ? userProData.disponibilities.length
                : 0;

            if (Array.isArray(projects)) {
                const validProjects = projects.filter(
                    (project) => project !== null && project !== undefined
                );
                totalProjects += validProjects.length;
            }
        }

        return totalProjects;
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Box sx={{ justifySelf: "center", mt: 6 }}>
                    {userSelector.societe ? (
                        <StructureForm />
                    ) : (
                        <ProfessionnelForm />
                    )}
                    <Typography
                        sx={{
                            mt: 5,
                            mb: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {t("project.on")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 3,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "2px",
                                marginRight: "2px",
                            }}
                        >
                            {" " + numberOfProjects() + " "}
                        </span>{" "}
                        {t("project.on.going")}
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            height: "auto",
                            mb: 10,
                            mt: 4,
                        }}
                    >
                        {userSelector.societe ? (
                            <StructureCalendar
                                disponibilities={userSelector.disponibilities}
                            />
                        ) : (
                            <ProfessionnelCalendar
                                disponibilities={userProData.disponibilities}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
