import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Typography,
    Stack,
    Collapse,
    IconButton,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import PaymentIcon from "@mui/icons-material/Payment";
import EditIcon from "@mui/icons-material/Edit";
import { TimeSheet as TimeSheetType } from "../types/TimeSheet.type";
import styled from "@emotion/styled";
import ApproveTimeSheet from "./ApproveTimeSheet";
import { useTranslation } from "react-i18next";
import { formatTimeHHMM, getApproved } from "../utils/utils";
import { calculateWorkingHours } from "../hooks/useTotal";
import { TimeSheet } from "./TimeSheet";

interface MultipleTimesheetProps {
    index?: number;
    date: string;
    timesheets: TimeSheetType[];
    missionId?: string;
    mode: "professional" | "structure";
    onEdit?: (id: string) => void;
}

const MultipleTimesheet: React.FC<MultipleTimesheetProps> = ({
    index = 0,
    date,
    timesheets,
    missionId,
    mode,
    onEdit,
}) => {
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const formattedDate = (() => {
        const parts = date.split("/");
        if (parts.length === 3) {
            const [day, month] = parts;
            return `${day}/${month}`;
        }
        return "Invalid Date";
    })();

    const totalBillableHours = useMemo(() => {
        return timesheets.reduce((sum, ts) => {
            const hours = calculateWorkingHours(
                ts.startDate,
                ts.endDate,
                false,
                ts.billable
            );
            return sum + (typeof hours === "number" ? hours : 0);
        }, 0);
    }, [timesheets]);

    const formattedTotalBillableTime = useMemo(() => {
        return formatTimeHHMM(totalBillableHours);
    }, [totalBillableHours]);

    const totalBillableAmount = useMemo(() => {
        return timesheets.reduce(
            (sum, ts) => sum + (ts.billable || ts.cost || 0),
            0
        );
    }, [timesheets]);

    const overallStatus = useMemo(() => {
        if (timesheets.some((ts) => ts.status === "rejected")) {
            return "rejected";
        }
        if (timesheets.some((ts) => ts.status === "pending")) {
            return "pending";
        }
        if (timesheets.every((ts) => ts.status === "approved")) {
            return "approved";
        }
        return "unknown";
    }, [timesheets]);
    const overallStatusInfo = getApproved(t, overallStatus);

    return (
        <SheetBox
            sx={{
                backgroundColor: index % 2 === 0 ? "#f7f7f7" : "white",
            }}
        >
            <StyledStack onClick={() => setExpanded(!expanded)}>
                <SheetDetails>{formattedDate}</SheetDetails>
                <SheetDetails> - </SheetDetails>
                <SheetDetails> - </SheetDetails>
                <SheetDetails>{formattedTotalBillableTime}</SheetDetails>
                <SheetDetails>{totalBillableAmount} €</SheetDetails>
                <Typography
                    justifySelf="center"
                    sx={{
                        backgroundColor: overallStatusInfo.backgroundColor,
                        display: "flex",
                        alignItems: "center",
                        color: "#FAF9F6",
                        justifyContent: "center",
                        borderRadius: "5px",
                        fontSize: "9px",
                        flex: "1",
                        "@media(width >= 768px)": {
                            fontSize: "12px",
                        },
                    }}
                >
                    {overallStatusInfo.label}
                </Typography>
                <SheetDetails>
                    <CollapseBtn onClick={() => setExpanded(!expanded)}>
                        {expanded ? (
                            <ExpandLessIcon fontSize="medium" />
                        ) : (
                            <ExpandMoreIcon fontSize="medium" />
                        )}
                    </CollapseBtn>
                </SheetDetails>
            </StyledStack>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="space-between"
                    justifyContent="center"
                    marginTop="0.5rem"
                    gap="0.5rem"
                    borderTop="1px solid #e0e0e0"
                >
                    {timesheets.map((timesheet) => (
                        <TimeSheet
                            key={timesheet.id}
                            timesheet={timesheet}
                            index={index}
                            missionId={missionId}
                            mode={mode}
                            onEdit={onEdit}
                        />
                    ))}
                </Box>
            </Collapse>
        </SheetBox>
    );
};

export default MultipleTimesheet;

const SheetBox = styled(Box)({
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    marginTop: "0.25rem",
});

const SheetDetails = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    padding: "1px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const CollapseBtn = styled(IconButton)({
    width: "30px",
    borderRadius: "1px",
    padding: 0,
    margin: 0,

    "@media(width >= 768px)": {
        width: "40px",
    },

    "@media(width >= 1240px)": {
        width: "100px",
    },

    "@media(width >= 1600px)": {
        width: "123px",
    },

    "@media(width >= 1900px)": {
        width: "142px",
    },
});

const StyledStack = styled(Stack)({
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1",
});
