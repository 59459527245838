import React, { useState } from "react";
import { Modal, Box, Typography, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../common/redux/store";
import { createTimeSheet } from "../../common/redux/missionActions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { RouteAPI } from "../../common/service/apiService";
import { TimeSheet } from "../types/TimeSheet.type";

interface TimesheetModalProps {
    isOpen: boolean;
    onClose: () => void;
    missionId: string;
    onSuccess: (newTimesheet: TimeSheet) => void;
    missionStartDate: string;
    missionEndDate: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const TimesheetModal: React.FC<TimesheetModalProps> = ({
    isOpen,
    onClose,
    missionId,
    onSuccess,
    missionStartDate,
    missionEndDate,
}) => {
    const [startTime, setStartTime] = useState<Dayjs | null>(null);
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const handleSubmit = () => {
        if (startTime && endTime) {
            const startDateTime = dayjs(startTime);
            const endDateTime = dayjs(endTime);
            const missionStart = dayjs(missionStartDate);
            const missionEnd = dayjs(missionEndDate).endOf("day");

            if (
                startDateTime.isBefore(missionStart) ||
                startDateTime.isAfter(missionEnd)
            ) {
                alert(t("mission.error.timesheet.invalidDateRange"));
                return;
            }

            if (
                endDateTime.isBefore(missionStart) ||
                endDateTime.isAfter(missionEnd)
            ) {
                alert(t("mission.error.timesheet.invalidDateRange"));
                return;
            }

            if (startDateTime.isAfter(endDateTime)) {
                alert(t("error.invalid.time.range"));
                return;
            }

            const payload = {
                mission: `${RouteAPI.MISSIONS}/${missionId}`,
                startDate: startDateTime.format(),
                endDate: endDateTime.format(),
            };

            dispatch(createTimeSheet(payload)).then(
                (response: { payload: TimeSheet }) => {
                    if (response.payload) {
                        const newTimesheet = response.payload as TimeSheet;
                        onSuccess(newTimesheet);
                    }
                }
            );

            setStartTime(null);
            setEndTime(null);
            onClose();
        } else {
            alert(t("error.missing.dates"));
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} disableEnforceFocus>
            <Box sx={modalStyles}>
                <Typography fontSize="18px" mb={2}>
                    {t("missions.add.timesheet")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={t("missions.hour.start")}
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        minDateTime={dayjs(missionStartDate)}
                        maxDateTime={dayjs(missionEndDate).endOf("day")}
                        ampm={false}
                        format="DD/MM/YYYY (HH:mm)"
                    />
                    <DateTimePicker
                        label={t("missions.hour.end")}
                        value={endTime}
                        onChange={(newValue) => {
                            console.log("Selected endTime:", newValue);
                            setEndTime(newValue);
                        }}
                        minDateTime={dayjs(missionStartDate)}
                        maxDateTime={dayjs(missionEndDate).endOf("day")}
                        ampm={false}
                        format="DD/MM/YYYY (HH:mm)"
                    />
                </LocalizationProvider>
                <Stack direction="row" spacing={2} mt={2}>
                    <Button variant="contained" onClick={handleSubmit}>
                        {t("missions.submit.button")}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        {t("missions.cancel.button")}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    "@media (min-width: 768px)": {
        width: 400,
    },
};
