import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "moment/locale/fr";
import "./index.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import Calendar from "./Calendar";
import { Stack } from "@mui/material";
import ValidatedEvent from "../assets/ValidatedEvent.png";
import AwaitingEvent from "../assets/AwaitingEvent.png";
import { useTranslation } from "react-i18next";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import { View, Views } from "react-big-calendar";
import { messages } from "./StructureCalendar";
import EventDialog from "./EventDialog";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatTime } from "../utils/FormatUtils";
import { getProjectsByIdPro } from "../structures/redux/projectAction";

interface SchedularProfessionnelProps {
    disponibilities: DisponibiliteProfessionnel[];
}

export const ProfessionnelCalendar: FunctionComponent<
    SchedularProfessionnelProps
> = ({ disponibilities }) => {
    const [view, setView] = useState<View>(Views.WEEK);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [, setMoreEvents] = useState<any[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const projects = useAppSelector((state: RootState) => state.projects.data);
    const userProData = useAppSelector(
        (state: RootState) => state.userPro.data
    );

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getProjectsByIdPro(userProData.id));
    }, [dispatch, userProData.id]);

    const mergedProjects = useMemo(() => {
        return [
            ...disponibilities,
            ...(Array.isArray(projects)
                ? projects
                : projects
                ? [projects]
                : []),
        ].filter((project) => project && project.id);
    }, [disponibilities, projects]);

    const uniqueProjects = useMemo(() => {
        return mergedProjects.reduce((acc, project) => {
            if (!acc.find((p: { id: string }) => p.id === project.id)) {
                acc.push(project);
            }
            return acc;
        }, [] as DisponibiliteProfessionnel[]);
    }, [mergedProjects]);

    const handleNavigate = (action: "previous" | "next") => {
        let daysToAdd = 1;

        if (view === Views.WEEK) {
            daysToAdd = 7;
        }

        const newDate =
            action === "previous"
                ? new Date(
                      currentDate.setDate(currentDate.getDate() - daysToAdd)
                  )
                : new Date(
                      currentDate.setDate(currentDate.getDate() + daysToAdd)
                  );

        setCurrentDate(newDate);
    };

    const handleOpen = (event: any) => {
        setSelectedEvent(event);
        setIsDialogOpen(true);
    };

    const handleShowMore = (events: any[], date: Date) => {
        setMoreEvents(events);
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setSelectedEvent(null);
        setMoreEvents([]);
    };

    const eventPropGetter = (
        event: any,
        start: Date,
        end: Date,
        isSelected: boolean
    ) => {
        let className = "";

        if (event.selection.includes("jour")) {
            className = "event-jour";
        } else if (event.selection.includes("nuit")) {
            className = "event-nuit";
        }

        className += " event-hover-effect";

        return { className };
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 990);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const schedulerData = uniqueProjects.map(
        (project: DisponibiliteProfessionnel) => ({
            id: project.id,
            startDate: project.dateDemarrage
                ? new Date(project.dateDemarrage)
                : new Date(),
            endDate: project.dateFin ? new Date(project.dateFin) : new Date(),
            startHour: project.heureDemarrage,
            endHour: project.heureFin,
            title: project.departements,
            subtitle: project.selection.join(", "),
            description: project.description,
            selection: project.selection,
            enabled: project.enabled,
        })
    );

    const events = schedulerData.map((item: any) => ({
        id: item.id,
        start: item.startDate,
        end: item.endDate,
        hourStart: item.startHour,
        hourEnd: item.endHour,
        title: item.title,
        description: item.description,
        enabled: item.enabled,
        selection: item.selection,
    }));

    const EventComponent = ({ event }: { event: any }) => {
        const flexDirectionStyle = view === Views.WEEK ? "column" : "row";
        const eventDescriptionFlex =
            isMobile && view === Views.WEEK ? "column" : "row";

        return (
            <>
                <EventContainer
                    onClick={() => handleOpen(event)}
                    style={{ flexDirection: flexDirectionStyle }}
                >
                    <Stack sx={{ flexDirection: "column" }}>
                        <EventDescription
                            style={{ flexDirection: eventDescriptionFlex }}
                        >
                            <Span>
                                <LocationOnIcon fontSize="small" />{" "}
                                {event.title}
                            </Span>
                        </EventDescription>
                        <EventDescription
                            style={{
                                flexDirection: eventDescriptionFlex,
                                marginTop: "0.5rem",
                                gap: "5px",
                            }}
                        >
                            <Time>
                                <AccessAlarmIcon fontSize="small" />
                                {formatTime(event.hourStart)}
                            </Time>
                            <Line>-</Line>
                            <Time>
                                <AlarmOnIcon fontSize="small" />
                                {formatTime(event.hourEnd)}
                            </Time>
                        </EventDescription>
                    </Stack>

                    {!event.enabled ? (
                        <ValidationBox>
                            <ValidationText>
                                {t("project.calendar.modal.validation")}
                            </ValidationText>
                            <img
                                src={AwaitingEvent}
                                alt="Awaiting Event"
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "10px",
                                    justifySelf: "end",
                                    textAlign: "right",
                                }}
                            />
                        </ValidationBox>
                    ) : (
                        <ValidationBox>
                            <ValidationText>
                                {t("project.calendar.validation")}
                            </ValidationText>
                            <img
                                src={ValidatedEvent}
                                alt="Awaiting Event"
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "10px",
                                    justifySelf: "end",
                                    textAlign: "right",
                                }}
                            />
                        </ValidationBox>
                    )}
                </EventContainer>
            </>
        );
    };

    return (
        <StyledStack className={`rbc-calendar ${view}-view`}>
            <NavigationButtons>
                <Button onClick={() => handleNavigate("previous")}>
                    <ArrowBackIcon />
                </Button>
                <Button onClick={() => handleNavigate("next")}>
                    <ArrowForwardIcon />
                </Button>
            </NavigationButtons>
            <Calendar
                events={events}
                date={currentDate}
                eventPropGetter={eventPropGetter}
                onSelectEvent={handleOpen}
                onShowMore={handleShowMore}
                components={{
                    event: EventComponent,
                }}
                messages={messages}
                view={view}
                onView={(newView: any) => setView(newView)}
                style={{
                    height: isMobile
                        ? view === "week"
                            ? "800px"
                            : view === "day"
                            ? "500px"
                            : "100%"
                        : view === "month"
                        ? "1000px"
                        : "640px",
                    overflow: "auto",
                }}
                views={
                    isMobile
                        ? ["week", "day", "agenda"]
                        : ["week", "day", "agenda"]
                }
                onNavigate={(newDate: Date) => setCurrentDate(newDate)}
            />

            <EventDialog
                selectedEvent={selectedEvent}
                isDialogOpen={isDialogOpen}
                handleClose={handleClose}
            />

            <StyledLink to="/projects/manage">
                {t("button.manage.projects")}{" "}
                <DoubleArrowIcon fontSize="large" />
            </StyledLink>
        </StyledStack>
    );
};

const StyledStack = styled(Stack)({
    width: "100dvw",
    height: "auto",
    position: "relative",

    "@media(width >= 1900px)": {
        height: "auto",
    },
});

const StyledLink = styled(Link)({
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "bold",
    background: "#ff8a80",
});

const Span = styled("span")({
    fontWeight: "bold",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    gap: "0.25rem",

    "@media(width >= 990px)": {
        fontSize: "14px",
    },
});

const EventContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%",
    justifyContent: "center",
    flex: 1,

    "@media(width >= 990px)": {
        flexDirection: "row",
        gap: "0.25rem",
        height: "80px",
        marginTop: "10px",
        padding: "5px",
    },
});

const EventDescription = styled("div")({
    display: "flex",
    alignItems: "flex-start",
    gap: "15px",
    width: "fit-content",

    "@media (min-width: 768px)": {
        flexDirection: "row",
        margin: 0,
        width: "320px",
    },
});

const ValidationText = styled("span")({
    fontSize: "12px",
    fontWeight: "bold",
});

const ValidationBox = styled("div")({
    display: "flex",
    justifySelf: "center",
    alignItems: "center",
    gap: "0.25rem",
    marginLeft: "auto !important",
    width: "fit-content",

    "@media(width >= 768px)": {
        width: "fit-content",
        marginLeft: "auto",
    },
});

const Time = styled("span")({
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.2rem",

    "@media(width >= 990px)": {
        fontSize: "14px",
        width: "30%",
    },
});

const NavigationButtons = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: "40%",
    left: "0",
    right: "0",
    margin: "0 auto",
    width: "100%",
    zIndex: 100,
    padding: "0 1rem",

    "@media(width <= 990px)": {
        display: "none",
    },
});

const Button = styled("button")({
    backgroundColor: "#ff8a80",
    color: "white",
    border: "none",
    fontSize: "1rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.6,
    gap: "0.5rem",
    borderRadius: "50%",
    width: "40px",
    height: "40px",

    ":hover": {
        opacity: 1,
        boxShadow:
            "0px 2px 4px rgba(0, 0, 0, 0.3), 0px 7px 7px -3px rgba(0, 0, 0, 0.3), inset 0px -3px 0px rgba(0, 0, 0, 0.2)",
    },
});

const Line = styled("span")({
    height: "2px",

    "@media(width <= 768px)": {
        display: "none",
    },
});
