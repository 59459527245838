import { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Divider, Typography, Box, Stack } from "@mui/material";
import ApproveTimeSheet from "./ApproveTimeSheet";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import PaymentIcon from "@mui/icons-material/Payment";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import { formatTimeHHMM, getApproved } from "../utils/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { RouteApp } from "../../common/model/RouteApp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/redux/store";
import { getMissionById } from "../../common/redux/missionActions";
import { calculateWorkingHours, useTotal } from "../hooks/useTotal";
import MultipleTimesheet from "./MultipleTimesheet";
import { TimeSheet as TimeSheetType } from "../types/TimeSheet.type";
import { TimeSheet } from "./TimeSheet";
import { WbSunny } from "@mui/icons-material";

export const MissionStructCard = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const mission = useSelector(
        (state: RootState) => state.missions.selectedMission
    );
    const { processedTimesheets, totalDailyWorkingTime, totalBillable } =
        useTotal(mission);

    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            dispatch(getMissionById({ id, role: "ROLE_STRUCTURE" }));
        }
    }, [id, dispatch]);

    const groupedTimesheets = useMemo(() => {
        return processedTimesheets.reduce(
            (groups: Record<string, TimeSheetType[]>, ts: TimeSheetType) => {
                const date = new Date(ts.startDate).toLocaleDateString(
                    "fr-FR",
                    {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }
                );

                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(ts);

                return groups;
            },
            {}
        );
    }, [processedTimesheets]);

    const combinedItems = useMemo(() => {
        const items: Array<{
            type: "individual" | "multiple";
            timesheet?: TimeSheetType;
            timesheets?: TimeSheetType[];
            startDate: Date;
        }> = [];

        Object.entries(groupedTimesheets).forEach(([_, timesheets]) => {
            const timesheetArray = timesheets as TimeSheetType[];

            if (timesheetArray.length > 1) {
                items.push({
                    type: "multiple",
                    timesheets: timesheetArray,
                    startDate: new Date(timesheetArray[0].startDate),
                });
            } else {
                items.push({
                    type: "individual",
                    timesheet: timesheetArray[0],
                    startDate: new Date(timesheetArray[0].startDate),
                });
            }
        });
        return items.sort(
            (a, b) => b.startDate.getTime() - a.startDate.getTime()
        );
    }, [groupedTimesheets]);

    return (
        <PageWrapper>
            <Stack marginTop="5rem">
                <StyledLink to={RouteApp.MISSION_BROWSER}>
                    <ArrowBackIcon fontSize="large" /> {t("missions.back.link")}
                </StyledLink>
            </Stack>
            <Container>
                <Card
                    sx={{
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        backgroundColor: "#f7f7f7",
                    }}
                >
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Reference fontWeight="bold">{mission.ref}</Reference>
                    </Stack>
                    <Structure>{mission.name}</Structure>
                    <Notes>{mission.notes}</Notes>
                    <Profession>
                        <MedicalServicesIcon />
                        {mission.job}
                    </Profession>
                    <Divider sx={{ marginY: 2 }} />
                    <Stack
                        sx={{
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <Typography variant="body1">
                            {new Date(mission.startDate).toLocaleDateString()}
                        </Typography>
                        <NextWeekIcon fontSize="large" />
                        <Typography variant="body1">
                            {new Date(mission.endDate).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Divider sx={{ marginY: 2 }} />
                    <SheetTitle>{t("missions.time.sheet.title")}</SheetTitle>
                    <Divider
                        orientation="horizontal"
                        flexItem
                        variant="fullWidth"
                        sx={{ marginTop: "1rem" }}
                    />
                    <Stack
                        flexDirection="row"
                        flex="1"
                        justifyContent="space-between"
                    >
                        <SheetTitleColumn>
                            <span className="text">
                                {t("select.date.start")}
                            </span>
                            <TodayIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.hour.start")} -{" "}
                                {t("missions.hour.end")}
                            </span>
                            <AccessTimeIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">{t("missions.period")}</span>
                            <WbSunny className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.billable.time")}
                            </span>
                            <AvTimerIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            <span className="text">
                                {t("missions.billable.amount")}
                            </span>
                            <PaymentIcon className="icon" />
                        </SheetTitleColumn>
                        <SheetTitleColumn>
                            {t("mission.status.status")}
                        </SheetTitleColumn>
                        <SheetTitleColumn>Action</SheetTitleColumn>
                    </Stack>
                    {combinedItems.map(
                        ({ type, timesheet, timesheets, startDate }, index) =>
                            type === "individual" && timesheet ? (
                                <TimeSheet
                                    key={index}
                                    timesheet={timesheet}
                                    index={index}
                                    missionId={mission}
                                    mode="structure"
                                />
                            ) : (
                                <MultipleTimesheet
                                    key={timesheets?.[0]?.id ?? index}
                                    index={index}
                                    date={startDate.toLocaleDateString(
                                        "fr-FR",
                                        {
                                            timeZone: "UTC",
                                        }
                                    )}
                                    timesheets={timesheets ?? []}
                                    missionId={mission.id}
                                    mode="structure"
                                />
                            )
                    )}

                    <Stack flex="1" marginTop="1rem" gap="1rem">
                        <Stack
                            display="flex"
                            flexDirection="row"
                            justifyContent="start"
                            alignItems="center"
                            gap="10px"
                        >
                            <Typography>TOTAL: </Typography>
                            {!mission.dailyBilling && (
                                <Typography
                                    display="flex"
                                    alignItems="center"
                                    gap="0.25rem"
                                >
                                    <AvTimerIcon />
                                    {formatTimeHHMM(totalDailyWorkingTime)}
                                </Typography>
                            )}

                            {!mission.dailyBilling && <ArrowForwardIcon />}
                            <Typography
                                display="flex"
                                alignItems="center"
                                gap="0.25rem"
                            >
                                <PaymentIcon />
                                {totalBillable.toFixed(2)}€
                            </Typography>
                        </Stack>

                        <Divider orientation="horizontal" />
                    </Stack>
                </Card>
            </Container>
        </PageWrapper>
    );
};

const PageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    minHeight: "100dvh",
    marginBottom: "2rem",
});

const Container = styled(Box)({
    height: "auto",
    width: "100%",
    margin: "3rem auto",

    "@media(width >= 768px)": {
        width: "70%",
    },

    "@media(width >= 990px)": {
        width: "60%",
    },
});

const Reference = styled(Typography)({
    fontSize: "14px",
    fontWeight: "bold",
    color: "#555555",
});

const Structure = styled(Typography)({
    fontSize: "16px",
    color: "#E53935",
    fontFamily: "Montserrat",
    width: "100%",
    textAlign: "center",
    marginTop: "1rem",

    "@media(width >= 990px)": {
        fontSize: "24px",
    },
});

const Profession = styled(Typography)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "1rem",
    fontSize: "14px",
});

const SheetDetails = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    padding: "1px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const SheetTitle = styled(Typography)({
    fontSize: "14px",
    fontWeight: "bold",

    "@media(width >= 990px)": {
        fontSize: "16px",
    },
});

const SheetTitleColumn = styled(Typography)({
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",

    "@media (width <= 989px)": {
        ".text": { display: "none" },
    },
    "@media (width >= 990px)": {
        ".icon": { display: "none" },
        fontSize: "13px",
        textAlign: "center",
    },
});

const Notes = styled(Typography)({
    fontSize: "14px",
    color: "#555555",
    fontFamily: "Montserrat",
    width: "100%",
    textAlign: "center",
    marginTop: "1rem",
});

const StyledStack = styled(Stack)({
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1",
});

const StyledLink = styled(Link)({
    display: "flex",
    alignItems: "center",
    marginLeft: "2rem",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: "black",
    gap: "10px",
    textDecoration: "none",

    "@media(width >= 990px)": {
        fontSize: "20px",
    },
});
