import React, { useState } from "react";
import {
    Modal,
    Box,
    Button,
    Typography,
    Stack,
    CircularProgress,
    IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../common/redux/store";
import {
    approveTimeSheet,
    rejectTimeSheet,
    getMissionById,
} from "../../common/redux/missionActions";
import { useTranslation } from "react-i18next";
import { useValidation } from "./useValidation";
import styled from "@emotion/styled";
import { TimeSheet } from "../types/TimeSheet.type";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { calculateWorkingHours } from "../hooks/useTotal";

interface ApproveTimeSheetProps {
    timesheetId: string;
    missionId: string;
    disabled?: boolean;
    timesheet: TimeSheet;
    onSuccess?: (newStatus?: string) => void;
}

const ApproveTimeSheet: React.FC<ApproveTimeSheetProps> = ({
    timesheet,
    timesheetId,
    missionId,
    disabled,
    onSuccess,
}) => {
    const { showSuccess, SnackbarComponent } = useValidation();
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const handleApproveModalOpen = () => setApproveModalOpen(true);
    const handleApproveModalClose = () => setApproveModalOpen(false);

    const handleRejectModalOpen = () => setRejectModalOpen(true);
    const handleRejectModalClose = () => setRejectModalOpen(false);

    const handleApprove = () => {
        setLoading(true);
        dispatch(approveTimeSheet(timesheetId))
            .unwrap()
            .then(() => {
                showSuccess(t("mission.approved.timesheet"));
                if (onSuccess) onSuccess("approved");
                dispatch(
                    getMissionById({ id: missionId, role: "ROLE_STRUCTURE" })
                );
            })
            .catch((error: string) => console.error("Approve Error:", error))
            .finally(() => {
                setLoading(false);
                handleApproveModalClose();
            });
    };

    const handleReject = () => {
        setLoading(true);
        dispatch(rejectTimeSheet(timesheetId))
            .unwrap()
            .then(() => {
                showSuccess(t("mission.rejected.timesheet"));
                if (onSuccess) onSuccess("rejected");
                dispatch(
                    getMissionById({ id: missionId, role: "ROLE_STRUCTURE" })
                );
            })
            .catch((error: string) => console.error("Reject Error:", error))
            .finally(() => {
                setLoading(false);
                handleRejectModalClose();
            });
    };

    const renderModalContent = (title: string, onConfirm: () => void) => (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 370,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                textAlign: "center",
            }}
        >
            <IconButton
                onClick={
                    title === t("mission.title.approve.timesheet")
                        ? handleApproveModalClose
                        : handleRejectModalClose
                }
                sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "grey.600",
                }}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Typography id="modal-title" textAlign="start">
                {title}
            </Typography>

            <Stack
                spacing={1}
                p={1}
                mt={4}
                textAlign="left"
                sx={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "10px",
                }}
            >
                <SheetDetail>
                    <p>{t("select.date.start")}:</p>
                    {new Date(timesheet.startDate).toLocaleString()}
                </SheetDetail>
                <SheetDetail>
                    <p>{t("project.date.end")}:</p>
                    {new Date(timesheet.endDate).toLocaleString()}
                </SheetDetail>
                <SheetDetail>
                    <p>{t("missions.billable.time")}:</p>
                    {calculateWorkingHours(
                        timesheet.startDate,
                        timesheet.endDate,
                        true
                    ) || 0}
                </SheetDetail>
                <SheetDetail>
                    <p>{t("missions.billable.amount")}:</p>
                    {timesheet.billable || 0}€
                </SheetDetail>
            </Stack>

            <Button
                sx={{ width: "fit-content", mt: 3 }}
                variant="contained"
                color={
                    title === t("mission.title.approve.timesheet")
                        ? "success"
                        : "error"
                }
                onClick={onConfirm}
                disabled={loading}
                startIcon={
                    loading && <CircularProgress size={16} color="inherit" />
                }
            >
                {loading
                    ? "Processing..."
                    : title === t("mission.title.approve.timesheet")
                    ? t("missions.accept.timesheet.button")
                    : t("missions.reject.timesheet.button")}
            </Button>
        </Box>
    );

    return (
        <Stack flexDirection="row" flex="1" justifyContent="space-evenly">
            <EditButton
                onClick={handleApproveModalOpen}
                disabled={disabled}
                sx={{
                    color: !disabled ? "green" : undefined,
                }}
            >
                <CheckBoxIcon
                    sx={{
                        fontSize: "26px",
                        "@media(width >= 768px)": {
                            fontSize: "28px",
                        },
                    }}
                />
            </EditButton>
            <EditButton
                onClick={handleRejectModalOpen}
                disabled={disabled}
                sx={{
                    color: !disabled ? "red" : undefined,
                }}
            >
                <DisabledByDefaultIcon
                    sx={{
                        fontSize: "26px",
                        "@media(width >= 768px)": {
                            fontSize: "28px",
                        },
                    }}
                />
            </EditButton>

            <Modal
                open={approveModalOpen}
                onClose={handleApproveModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                {renderModalContent(
                    t("mission.title.approve.timesheet"),
                    handleApprove
                )}
            </Modal>

            <Modal
                open={rejectModalOpen}
                onClose={handleRejectModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                {renderModalContent(
                    t("mission.title.reject.timesheet"),
                    handleReject
                )}
            </Modal>

            {SnackbarComponent}
        </Stack>
    );
};

export default ApproveTimeSheet;

const EditButton = styled(Button)({
    margin: 0,
    width: "24px !important",
    minWidth: "24px !important",
    padding: "1px",

    "@media(width >= 768px)": {
        width: "30px !important",
    },
});

const SheetDetail = styled(Typography)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    color: "#222222",
});
