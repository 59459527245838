import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    ProfessionnelMission,
    ProfessionnelMissionStats,
} from "../../missions/model/professionnelTypes";
import { api, RouteAPI } from "../service/apiService";
import { ResponseMessage } from "../model/ResponseMessage";

import {
    mapProfessionnelMission,
    mapStructureMission,
} from "../../missions/utils/missionMapperRole";
import {
    StructureMission,
    StructureMissionStats,
} from "../../missions/model/structureTypes";
import dayjs from "dayjs";

interface TimeSheetPayload {
    mission: string;
    startDate: string;
    endDate: string;
}

interface FetchMissionsParams {
    page: number;
    itemsPerPage: number;
    sortOrder: string;
    search: string;
}

interface editTimeSheet {
    id: string;
    startDate: string;
    endDate: string;
}

export const userMissions = createAsyncThunk<
    (ProfessionnelMission | StructureMission)[],
    FetchMissionsParams & { role: string }
>(
    "missions/userMissions",
    async ({ page, itemsPerPage, sortOrder, search, role }, thunkAPI) => {
        try {
            const response = await api<
                (ProfessionnelMission | StructureMission)[]
            >(
                `${RouteAPI.MISSIONS}?page=${page}&itemsPerPage=${itemsPerPage}&order[startDate]=${sortOrder}&search=${search}`,
                "GET"
            );

            const data =
                role === "ROLE_PROFESSIONNEL"
                    ? response.data.map(mapProfessionnelMission)
                    : response.data.map(mapStructureMission);

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError);
        }
    }
);

export const getMissionById = createAsyncThunk<
    ProfessionnelMission | StructureMission,
    { id: string; role: string }
>("missions/getMissionById", async ({ id, role }, thunkAPI) => {
    try {
        const response = await api<ProfessionnelMission | StructureMission>(
            `${RouteAPI.MISSIONS}/${id}`,
            "GET"
        );

        const data =
            role === "ROLE_PROFESSIONNEL"
                ? mapProfessionnelMission(response.data)
                : mapStructureMission(response.data);

        console.log(response.data);
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as ResponseMessage);
    }
});

export const createTimeSheet = createAsyncThunk<
    ProfessionnelMission,
    TimeSheetPayload
>("missions/createTimeSheet", async (payload, thunkAPI) => {
    try {
        const response = await api<ProfessionnelMission>(
            `${RouteAPI.MISSIONS_TIMESHEET}`,
            "POST",
            payload
        );
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as ResponseMessage);
    }
});

export const updateTimeSheet = createAsyncThunk<
    ProfessionnelMission,
    editTimeSheet
>("missions/updateTimeSheet", async (payload, thunkAPI) => {
    try {
        const { id, ...updateData } = payload;
        const response = await api<ProfessionnelMission>(
            `${RouteAPI.MISSIONS_TIMESHEET}/${id}`,
            "PATCH",
            updateData
        );
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as ResponseMessage);
    }
});

export const deleteTimeSheet = createAsyncThunk<ProfessionnelMission, string>(
    "missions/deleteTimeSheet",
    async (timesheetId, thunkAPI) => {
        try {
            const response = await api<ProfessionnelMission>(
                `${RouteAPI.MISSIONS_TIMESHEET}/${timesheetId}`,
                "DELETE"
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseMessage);
        }
    }
);

export const getMissionStatistics = createAsyncThunk<
    ProfessionnelMissionStats[] | StructureMissionStats[],
    { startDate: number; endDate: number }
>("missions/getStatistics", async (params, thunkAPI) => {
    const startDate = dayjs(params.startDate * 1000)
        .utc()
        .startOf("day")
        .unix();
    const endDate = dayjs(params.endDate * 1000)
        .utc()
        .endOf("day")
        .unix();
    try {
        const response = await api<
            ProfessionnelMissionStats[] | StructureMissionStats[]
        >(`${RouteAPI.MISSIONS_STATS}`, "GET", undefined, {
            start_date: startDate,
            end_date: endDate,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching mission statistics:", error);
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
});

export const approveTimeSheet = createAsyncThunk<ProfessionnelMission, string>(
    "missions/approveTimeSheet",
    async (timesheetId, thunkAPI) => {
        try {
            const response = await api<ProfessionnelMission>(
                `${RouteAPI.MISSIONS_TIMESHEET}/${timesheetId}`,
                "PATCH",
                { status: "approved" }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseMessage);
        }
    }
);

export const rejectTimeSheet = createAsyncThunk<ProfessionnelMission, string>(
    "missions/rejectTimeSheet",
    async (timesheetId, thunkAPI) => {
        try {
            const response = await api<ProfessionnelMission>(
                `${RouteAPI.MISSIONS_TIMESHEET}/${timesheetId}`,
                "PATCH",
                { status: "rejected" }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseMessage);
        }
    }
);
