export interface DisponibiliteProfessionnel {
    account: UserProfessionnelResponse;
    departements: string[];
    id: string;
    profession: string[];
    selection: string[];
    description: string;
    enabled: boolean;
    dateDemarrage: Date;
    dateFin: Date;
    heureDemarrage: string;
    heureFin: string;
    isInterested: { status: string } | null;
    interested: [] | null;
    isMock?: boolean;
    profilImageUrl?: string;
    profileImage?: { contentUrl: string; id: string };
    profile_image?: string;
    indexOfDisponibility?: number;
    fulfilled?: boolean;
}

export interface UserProfessionnelResponse {
    nom: string;
    prenom: string;
    profession: string[];
    disponibilities: DisponibiliteProfessionnel[];
    id: string;
    email: string;
    roles: string[];
    enabled: boolean;
    telephone: string;
    profile_image?: string;
    profileImage?: { contentUrl: string; id: string };
    description: string;
    indexOfDisponibility?: number;
    selection?: string[];
    departements?: string[];
}

export const emptyUserProfessionnel: UserProfessionnelResponse = {
    nom: "",
    prenom: "",
    profession: [""],
    disponibilities: [],
    id: "",
    email: "",
    roles: [],
    enabled: false,
    profile_image: "",
    telephone: "",
    profileImage: { contentUrl: "", id: "" },
    description: "",
    selection: ["", ""],
    departements: [],
};

export interface DisponibiliteStructure {
    account: UserStructureResponse;
    id: string;
    ville: string;
    profession: string[];
    disponibilities: string[];
    selection: string[];
    description: string;
    societe: string;
    email: string;
    siret: string;
    enabled: boolean;
    dateDemarrage: Date;
    dateFin: Date;
    isInterested: { status: string } | null;
    isMock?: boolean;
    profilImageUrl?: string;
    indexOfDisponibility?: number;
    fulfilled?: boolean;
}

export interface UserStructureResponse {
    societe: string;
    siret: string;
    disponibilities: DisponibiliteStructure[];
    id: string;
    email: string;
    roles: string[];
    enabled: boolean;
    ville: string;
    profession: string[];
    telephone: string;
    profileImage?: { contentUrl: string; id: string };
    description: string;
}

export const emptyUserStructure: UserStructureResponse = {
    societe: "",
    siret: "",
    profession: [""],
    disponibilities: [],
    id: "",
    ville: "",
    email: "",
    roles: [],
    enabled: false,
    telephone: "",
    profileImage: { contentUrl: "", id: "" },
    description: "",
};
