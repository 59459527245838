import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { getUser, getUserProfessionnel } from "../common/redux/userAction";
import { RootState } from "../common/redux/store";
import { useNavigate } from "react-router-dom";
import { ManageStructureCard } from "../cards/ManageStructureCard";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    DisponibiliteStructure,
    UserStructureResponse,
} from "../structures/model/StructureModel";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import { ManageProfessionnelCard } from "../cards/ManageProfessionnelCard";
import { RouteUtils } from "../utils/RouteUtils";
import EmptyProject from "../common/components/EmptyProject";

export const ManageProjectsPage = () => {
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const userProData = useAppSelector(
        (state: RootState) => state.userPro.data
    );

    const projectSelector: any | undefined = useAppSelector(
        (state: RootState) => state.projects
    );

    const [account, setAccount] = useState<UserStructureResponse>(userSelector);
    const [, setIsDisplayPopUpCreateProject] = useState(false);
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBackClick = useCallback(() => {
        navigate(RouteUtils.PROJECT);
    }, [navigate]);

    const StructureCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid
                item
                xl={5}
                key={i}
                m={1}
                xs={6}
                lg={6}
                sx={{ width: "min-content" }}
            >
                <ManageStructureCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                />
            </Grid>
        );
    };

    const ProfessionnelCalled = (
        props: DisponibiliteProfessionnel,
        i: number
    ) => {
        return (
            <Grid
                item
                xl={5}
                key={i}
                m={1}
                xs={6}
                lg={6}
                sx={{ width: "min-content" }}
            >
                <ManageProfessionnelCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                />
            </Grid>
        );
    };

    const numberOfProjects = () => {
        if (userSelector.societe && userSelector.disponibilities) {
            return userSelector.disponibilities.length;
        } else if (userProData.disponibilities) {
            return userProData.disponibilities.length;
        }
        return 0;
    };

    useEffect(() => {
        if (userSelector.societe) {
            dispatch(getUser(userSelector?.id)).then(() =>
                setIsDisplayPopUpCreateProject(true)
            );
            setAccount(userSelector);
        } else {
            dispatch(getUserProfessionnel(userProData?.id));
            setAccount(userProData);
        }

        if (projectSelector.isCreateProject) {
            setIsDisplayPopUpCreateProject(true);
        }
    }, [dispatch, projectSelector, userSelector, userProData]);

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    marginTop: "120px",
                }}
            >
                <Title>Gérez vos Projets</Title>
                <Box
                    sx={{
                        justifySelf: "center",
                        width: "100%",
                        marginBottom: "4rem",
                    }}
                >
                    <Typography
                        variant="h6"
                        mt={3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <Button
                            variant="text"
                            size="medium"
                            sx={{
                                padding: 0,
                                marginTop: "40px",
                                display: "contents",
                            }}
                            onClick={handleBackClick}
                        >
                            <ArrowBackIcon
                                fontSize="medium"
                                sx={{ height: 30, width: 30, mr: 1, ml: 2 }}
                            />
                        </Button>
                        {t("project.availability.link")}
                    </Typography>
                    <Typography
                        sx={{
                            mt: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {t("project.on")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 3,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "2px",
                                marginRight: "2px",
                            }}
                        >
                            {" " + numberOfProjects() + " "}
                        </span>{" "}
                        {t("project.on.going")}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            gap: "2rem",
                        }}
                    >
                        {account && account.societe
                            ? userSelector.disponibilities.map((dispo, i) =>
                                  StructureCalled(dispo, i)
                              )
                            : userProData.disponibilities.map(
                                  (
                                      dispo: DisponibiliteProfessionnel,
                                      i: number
                                  ) => ProfessionnelCalled(dispo, i)
                              )}
                    </Box>
                </Box>
                {numberOfProjects() === 0 && <EmptyProject />}
            </Box>
        </>
    );
};

const Title = styled(Typography)({
    fontSize: "clamp(1.5rem, 2vw, 2rem)",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
});
