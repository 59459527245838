export const getStatus = (status: string) => {
    switch (status) {
        case "à venir":
            return { backgroundColor: "#ff964f" };
        case "En cours":
            return { backgroundColor: "#5D9CEC" };
        case "Passé":
            return { backgroundColor: "#4CAF50" };
        default:
            return {};
    }
};

export const getApproved = (t: (key: string) => string, status: string) => {
    const statusMap: Record<string, string> = {
        approved: t("mission.status.approved"),
        pending: t("mission.status.pending"),
        rejected: t("mission.status.rejected"),
    };

    const translatedKey = statusMap[status];
    switch (status) {
        case "approved":
            return {
                backgroundColor: "#008200",
                label: translatedKey,
            };
        case "pending":
            return {
                backgroundColor: "#899499",
                label: translatedKey,
            };
        case "rejected":
            return {
                backgroundColor: "#d32f2f",
                label: translatedKey,
            };
        default:
            return {};
    }
};

export const formatDate = (
    dateString: string | number | Date,
    ignoreTimeZone: boolean = false
) => {
    const date = new Date(
        ignoreTimeZone ? String(dateString).slice(0, 19) : dateString
    );
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatTimeHHMM = (decimalHours: number) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
    )}`;
};
