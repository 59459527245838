import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    IStateReducer,
    initialStateReducer,
} from "../../common/model/IStateReducer";
import { AxiosError } from "axios";
import { AvatarResponse, UserStructureResponse } from "../model/StructureModel";
import { ResponseViolations, postAvatar, signUp } from "./signUpAction";
import { createProject, createProjectProfessionnel } from "./projectAction";
import { UserProfessionnelResponse } from "../../professionnels/model/ProfessionnelModel";

const initialState: IStateReducer<
    (UserStructureResponse | UserProfessionnelResponse)[]
> = {
    ...initialStateReducer,
    data: [],
};

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        newProject: (state, action) => {
            state.data = [...state.data, action.payload.data];
        },
        dropProject: (state) => {
            state.isExtraField = false;
            state.data = [];
        },
        getProjects: (state, action) => {
            state.data = [...state.data, ...action.payload.data];
        },
    },
    extraReducers(builder) {
        builder.addCase(postAvatar.pending, (state) => {
            state.isLoader = true;
            state.isExtraField = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "POST";
        });
        builder.addCase(
            postAvatar.fulfilled,
            (state, action: PayloadAction<AvatarResponse>) => {
                if (state.data && "profileImage" in state.data) {
                    state.data.profileImage = action.payload;
                } else {
                    console.warn(
                        "⚠️ profileImage does not exist on the user object"
                    );
                }

                state.isExtraField = false;
                state.isLoader = false;
                state.isSuccess = true;
                state.requestType = "POST";
            }
        );
        builder.addCase(
            postAvatar.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isExtraField = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseViolations>;
                state.requestType = "POST";
            }
        );

        builder.addCase(signUp.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "POST";
        });
        builder.addCase(signUp.fulfilled, (state, action) => {
            if (Array.isArray(state.data)) {
                state.data.push(action.payload);
            } else {
                state.data = [action.payload];
            }
            state.isLoader = false;
            state.isSuccess = true;
            state.requestType = "POST";
        });
        builder.addCase(signUp.rejected, (state, action) => {
            state.isLoader = false;
            state.isSuccess = false;
            state.error = action.payload as AxiosError<ResponseViolations>;
            state.requestType = "POST";
        });

        builder.addCase(createProject.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.isCreateProject = false;
            state.requestType = "POST";
        });
        builder.addCase(createProject.fulfilled, (state, action) => {
            state.isLoader = false;
            state.isSuccess = true;
            state.isCreateProject = true;
            state.requestType = "POST";

            if (Array.isArray(state.data)) {
                state.data.push(action.payload);
            } else {
                state.data = [action.payload];
            }

            if (state.data.length > 10) {
                state.data = [];
            }
        });
        builder.addCase(createProject.rejected, (state, action) => {
            state.isLoader = false;
            state.isSuccess = false;
            state.isCreateProject = false;
            state.error = action.payload as AxiosError<ResponseViolations>;
            state.requestType = "POST";
        });

        builder.addCase(createProjectProfessionnel.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.isCreateProject = false;
            state.requestType = "POST";
        });
        builder.addCase(
            createProjectProfessionnel.fulfilled,
            (state, action) => {
                state.isLoader = false;
                state.isSuccess = true;
                state.isCreateProject = true;
                state.requestType = "POST";

                if (Array.isArray(state.data)) {
                    state.data.push(action.payload);
                } else {
                    state.data = [action.payload];
                }

                if (state.data.length > 10) {
                    state.data = [];
                }
            }
        );

        builder.addCase(
            createProjectProfessionnel.rejected,
            (state, action) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.isCreateProject = false;
                state.error = action.payload as AxiosError<ResponseViolations>;
                state.requestType = "POST";
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { newProject, dropProject, getProjects } = projectSlice.actions;

export default projectSlice;
