import React, { useEffect, useState } from "react";
import { api } from "../common/service/apiService";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "@mui/material/Modal";

export function UploadDocument() {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [documentName, setDocumentName] = useState("");
    const [documentUri, setDocumentUri] = useState<File | null>(null);
    const [description, setDescription] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string>("");

    const { t } = useTranslation();

    useEffect(() => {
        if (documentUri) {
            const url = URL.createObjectURL(documentUri);
            setPreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [documentUri]);

    const openDocumentPicker = async () => {
        try {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = "application/pdf, image/png, image/jpeg";
            fileInput.click();

            fileInput.onchange = (e: any) => {
                const file = e?.target?.files[0];
                if (file) {
                    setDocumentName(file.name);
                    setDocumentUri(file);
                }
            };
        } catch (error) {
            console.error("Error picking document:", error);
        }
    };

    const uploadDocument = async () => {
        setIsLoading(true);
        setSuccessMessage("");

        try {
            const formData = new FormData();
            formData.append("file", documentUri as File);
            formData.append("name", documentName);
            formData.append("description", description);

            await api("/api/documents/send", "POST", formData, {
                "Content-Type": "multipart/form-data",
                onUploadProgress: (progressEvent: any) => {
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(progress);
                },
            });

            setIsLoading(false);
            setUploadProgress(100);

            setSuccessMessage(t("profile.document.upload.success"));

            setTimeout(() => {
                setDocumentName("");
                setDocumentUri(null);
                setDescription("");
                setUploadProgress(0);
                setSuccessMessage("");
            }, 3000);
        } catch (error) {
            console.error("Error uploading document:", error);
            setIsLoading(false);
            setUploadProgress(0);
        }
    };

    return (
        <Container>
            <UploadSection>
                <StyledButton
                    onClick={openDocumentPicker}
                    startIcon={<DriveFolderUploadIcon />}
                >
                    {t("button.upload.document")}
                </StyledButton>

                <StyledInput
                    type="text"
                    placeholder={t("profile.document.description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                {isLoading ? (
                    <CircularProgress color="primary" size={24} />
                ) : (
                    <StyledSendButton
                        onClick={uploadDocument}
                        endIcon={<SendIcon />}
                        disabled={!documentUri}
                    >
                        {t("button.send.document")}
                    </StyledSendButton>
                )}
            </UploadSection>

            {documentName && (
                <FileInfoContainer>
                    <DocumentName>{documentName}</DocumentName>
                    <PreviewLink onClick={() => setShowPreview(true)}>
                        {t("profile.document.preview")}
                        <VisibilityIcon fontSize="small" />
                    </PreviewLink>
                </FileInfoContainer>
            )}

            {successMessage && (
                <SuccessMessage>{successMessage}</SuccessMessage>
            )}

            <Modal open={showPreview} onClose={() => setShowPreview(false)}>
                <ModalContent>
                    {documentUri && documentUri.type.startsWith("image/") && (
                        <img
                            src={previewUrl}
                            alt="Preview"
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                    )}
                    {documentUri && documentUri.type === "application/pdf" && (
                        <iframe
                            src={previewUrl}
                            style={{ width: "100%", height: "100%" }}
                            title="PDF Preview"
                        />
                    )}
                </ModalContent>
            </Modal>
        </Container>
    );
}

export default UploadDocument;

const Container = styled(Stack)({
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    marginTop: "1rem",
    backgroundColor: "#F8F9FA",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
});

const UploadSection = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    width: "100%",

    "@media(width >= 768px)": {
        flexDirection: "row",
    },
});

const StyledButton = styled(Button)({
    width: "100%",
    backgroundColor: "#155E95",
    color: "#FFF",
    textTransform: "none",
    padding: "0.6rem 1.2rem",
    borderRadius: "5px",
    "&:hover": {
        backgroundColor: "#155E95",
        opacity: "95%",
    },

    "@media(width >= 768px)": {
        width: "fit-content",
    },
});

const StyledSendButton = styled(Button)({
    width: "100%",
    backgroundColor: "#72BF78",
    color: "#FFF",
    textTransform: "none",
    padding: "0.6rem 1.2rem",
    borderRadius: "5px",

    "&:hover": {
        backgroundColor: "#218838",
    },
    "@media(width >= 768px)": {
        width: "fit-content",
    },
});

const StyledInput = styled("input")({
    width: "100%",
    height: "80px",
    padding: "0.6rem",
    borderRadius: "8px",
    border: "1px solid #CED4DA",
    fontSize: "14px",
    outline: "none",
    "&:focus": {
        borderColor: "grey",
    },

    "@media(width >= 768px)": {
        width: "50%",
        height: "auto",
    },

    "@media(width >= 990px)": {
        width: "60%",
    },
});

const DocumentName = styled("span")({
    fontSize: "13px",
    color: "#6C757D",
});

const SuccessMessage = styled("div")({
    marginTop: "0.25rem",
    padding: "0.5rem 1rem",
    backgroundColor: "#d4edda",
    color: "#155724",
    border: "1px solid #c3e6cb",
    borderRadius: "4px",
    fontSize: "12px",
});

const FileInfoContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
    marginTop: "1rem",

    "@media(width >= 768px)": {
        flexDirection: "row",
    },
});

const PreviewLink = styled("a")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.25rem",
    fontSize: "13px",
    color: "#007BFF",
    cursor: "pointer",
    textDecoration: "underline",

    ":hover": {
        color: "#155E95",
    },
});

const ModalContent = styled("div")({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    backgroundColor: "#FFF",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
    padding: "1rem",
    overflow: "auto",
});
