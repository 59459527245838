import { useEffect, useState, useCallback } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import styled from "@emotion/styled";
import { getMissionStatistics } from "../../common/redux/missionActions";
import FilterStatisticsDashboard from "./FilterStatisticsDashboard";
import { ProfessionnelMissionStats } from "../model/professionnelTypes";

dayjs.extend(isBetween);

export const ProfessionnelDashboard = () => {
    const [missionsStats, setMissionsStats] = useState<
        ProfessionnelMissionStats[]
    >([]);
    const [startDate, setStartDate] = useState<Dayjs | null>(
        dayjs().startOf("month")
    );
    const [endDate, setEndDate] = useState<Dayjs | null>(
        dayjs().endOf("month")
    );

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const fetchMissionsStats = useCallback(async () => {
        if (!startDate || !endDate) return;

        try {
            const data = await dispatch(
                getMissionStatistics({
                    startDate: startDate.unix(),
                    endDate: endDate.unix(),
                })
            );

            if (data?.payload && !Array.isArray(data.payload))
                throw new Error("Response data is not an array");

            setMissionsStats(data.payload);
        } catch (error) {
            console.error("Error fetching mission statistics", error);
        }
    }, [startDate, endDate, dispatch]);

    useEffect(() => {
        fetchMissionsStats();
    }, [startDate, endDate]);

    const handleClearFilter = () => {
        const startOfMonth = dayjs().startOf("month");
        const endOfMonth = dayjs().endOf("month");
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
    };

    const getStatus = (startDate: string, endDate: string) => {
        const today = dayjs();
        const start = dayjs(startDate.slice(0, 19));
        const end = dayjs(endDate.slice(0, 19));

        if (today.isBefore(start)) return t("mission.status.upcoming");
        if (today.isBetween(start, end)) return t("mission.status.progress");
        return t("mission.status.past");
    };

    const getStatusStyle = (status: string) => ({
        backgroundColor:
            {
                [t("mission.status.progress")]: "#F28C28",
                [t("mission.status.past")]: "#008200",
                [t("mission.status.upcoming")]: "#818589",
            }[status] || "#899499",
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <AccordionContainer>
                    <AccordionSummary
                        expandIcon={
                            <KeyboardDoubleArrowDownIcon
                                fontSize="large"
                                sx={{ color: "black" }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ height: "auto" }}
                    >
                        <AccordionSummaryStatus>
                            <DashboardTitle>
                                {t("mission.dashboard.title")}
                            </DashboardTitle>
                        </AccordionSummaryStatus>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DashboardContainer>
                            <FilterStatisticsDashboard
                                startDate={startDate}
                                endDate={endDate}
                                onStartDateChange={setStartDate}
                                onEndDateChange={setEndDate}
                                onClear={handleClearFilter}
                            />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableTitle>
                                                {t("mission.dashboard.mission")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.dashboard.time")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.dashboard.amount")}
                                            </TableTitle>
                                            <TableTitle>
                                                {t("mission.status.status")}
                                            </TableTitle>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {missionsStats.length > 0 ? (
                                            missionsStats.map((mission) => (
                                                <TableRow key={mission.id}>
                                                    <TableInfo>
                                                        {mission.name}
                                                    </TableInfo>
                                                    <TableInfo>
                                                        {mission.dailyBilling
                                                            ? `${
                                                                  mission.totals
                                                                      ?.time ||
                                                                  "N/A"
                                                              } ${t(
                                                                  "mission.dashboard.days"
                                                              )}`
                                                            : `${
                                                                  mission.totals
                                                                      ?.time ||
                                                                  "N/A"
                                                              } Hrs`}
                                                    </TableInfo>
                                                    <TableInfo>
                                                        {mission.totals?.amount
                                                            ? `${mission.totals.amount.toFixed(
                                                                  2
                                                              )} €`
                                                            : "N/A"}
                                                    </TableInfo>
                                                    <TableStatus
                                                        sx={{
                                                            backgroundColor:
                                                                getStatusStyle(
                                                                    getStatus(
                                                                        mission
                                                                            .startDate
                                                                            .date,
                                                                        mission
                                                                            .endDate
                                                                            .date
                                                                    )
                                                                ),
                                                        }}
                                                    >
                                                        {getStatus(
                                                            mission.startDate
                                                                .date,
                                                            mission.endDate.date
                                                        )}
                                                    </TableStatus>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align="center"
                                                >
                                                    <Typography>
                                                        {t(
                                                            "mission.message.notfound.mission"
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DashboardContainer>
                    </AccordionDetails>
                </AccordionContainer>
            </Stack>
        </LocalizationProvider>
    );
};

const AccordionContainer = styled(Accordion)({
    width: "100%",
    margin: "20px 0 40px 0",
    backgroundColor: "#f7f7f7",
    boxShadow:
        "0px 2px 4px rgba(0, 0, 0, 0.4), 0px 7px 7px -3px rgba(0, 0, 0, 0.15), inset 0px -3px 0px rgba(0, 0, 0, 0.2)",
    "@media(width >= 990px)": {
        width: "80%",
    },
});

const DashboardContainer = styled("div")({
    width: "100%",
    padding: "20px",
    backgroundColor: "#FAF9F6",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
});

const DashboardTitle = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: "16px",
    textAlign: "start",
    padding: "5px",
    paddingRight: "10px",
    borderRight: "1px solid #E0E0E0",

    "@media(width >= 768px)": {
        fontSize: "18px",
        padding: "20px",
    },
});

const AccordionSummaryStatus = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    "@media(width >= 768px)": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
    },
});

const TableTitle = styled(TableCell)({
    fontSize: "12.5px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const TableInfo = styled(TableCell)({
    fontSize: "10px",
    textAlign: "center",
    "@media(width >= 768px)": {
        textAlign: "start",
        fontSize: "13px",
    },
});

const TableStatus = styled(TableCell)({
    fontSize: "10px",
    color: "#FAF9F6",
    textAlign: "center",
    borderRadius: "5px",
    "@media(width >= 768px)": {
        width: "90px",
    },
    "@media(width >= 990px)": {
        width: "120px",
        fontSize: "14px",
    },
});
