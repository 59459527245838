import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RouteAPI, api } from "../../common/service/apiService";
import {
    CreateDisponibiliteStructure,
    DisponibiliteStructure,
} from "../model/StructureModel";
import { dropProject, getProjects, newProject } from "./projectSlice";
import { ResponseViolations } from "./signUpAction";
import {
    CreateDisponibiliteProfessionnel,
    UserProfessionnelResponse,
} from "../../professionnels/model/ProfessionnelModel";
import { DisponibiliteProfessionnel } from "../../search/model/Disponibilite";

export const createProject = createAsyncThunk<
    DisponibiliteStructure,
    CreateDisponibiliteStructure
>(
    "project/create",
    async (data: CreateDisponibiliteStructure, thunkAPI: any) => {
        try {
            const response: DisponibiliteStructure = await api(
                RouteAPI.DISPONIBILITE_STRUCTURE,
                "POST",
                data
            ).then((res) => res.data);
            thunkAPI.dispatch(newProject(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const createProjectProfessionnel = createAsyncThunk<
    UserProfessionnelResponse,
    CreateDisponibiliteProfessionnel
>(
    "project/createProfessionnel",
    async (data: CreateDisponibiliteProfessionnel, thunkAPI: any) => {
        try {
            const response: UserProfessionnelResponse = await api(
                RouteAPI.DISPONIBILITE_PROFESSIONNELS,
                "POST",
                data
            ).then((res) => res.data);
            thunkAPI.dispatch(newProject(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const fetchDisponibiliteStructure = createAsyncThunk(
    "disponibiliteStructure/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api(RouteAPI.DISPONIBILITE_STRUCTURE, "GET");
            return response.data as DisponibiliteStructure[];
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchDisponibiliteProfessionnel = createAsyncThunk(
    "disponibiliteProfessionnel/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api(
                RouteAPI.DISPONIBILITE_PROFESSIONNELS,
                "GET"
            );
            return response.data as DisponibiliteProfessionnel[];
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteProject = createAsyncThunk<any, string>(
    "project/deleteProject",
    async (id: string, thunkAPI: any) => {
        try {
            const response: any = await api(
                RouteAPI.DISPONIBILITE_STRUCTURE + "/" + id,
                "DELETE",
                {}
            ).then((res) => res.data);
            thunkAPI.dispatch(dropProject(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const deleteAvailability = createAsyncThunk<any, string>(
    "project/deleteAvailability",
    async (id: string, thunkAPI: any) => {
        try {
            const response: any = await api(
                RouteAPI.DISPONIBILITE_PROFESSIONNELS + "/" + id,
                "DELETE",
                {}
            ).then((res) => res.data);
            thunkAPI.dispatch(dropProject(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const getProjectsById = createAsyncThunk<any, string>(
    "project/get",
    async (id: string, thunkAPI: any) => {
        try {
            const response: any = await api(
                RouteAPI.STRUCTURES + "/" + id,
                "GET",
                {}
            ).then((res) => res.data);
            thunkAPI.dispatch(getProjects(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const getProjectsByIdPro = createAsyncThunk<any, string>(
    "project/getPro",
    async (id: string, thunkAPI: any) => {
        try {
            const response: any = await api(
                RouteAPI.PROFESSIONNELS + "/" + id,
                "GET",
                {}
            ).then((res) => res.data);
            thunkAPI.dispatch(getProjects(response));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);
export { getProjects };
