import styled from "@emotion/styled";
import { TimeSheet as TimeSheetType } from "../types";
import { Box, Button, Stack, Typography } from "@mui/material";
import { calculateWorkingHours } from "../hooks/useTotal";
import ApproveTimeSheet from "./ApproveTimeSheet";
import { getApproved } from "../utils/utils";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TimeSheetPeriodLabel } from "./TimeSheetPeriodLabel";
import { EditNotifications } from "@mui/icons-material";

export interface TimesheetProps {
    index?: number;
    timesheet: TimeSheetType;
    missionId?: string;
    mode: "professional" | "structure";
    onEdit?: (id: string) => void;
}

export const TimeSheet = ({
    index = 0,
    timesheet,
    missionId,
    mode,
    onEdit,
}: TimesheetProps) => {
    const { t } = useTranslation();

    const timesheetDates = useMemo(() => {
        return {
            start: new Date(timesheet.startDate),
            end: new Date(timesheet.endDate),
        };
    }, [timesheet]);

    return (
        <SheetBox
            sx={{
                backgroundColor: index % 2 === 0 ? "#f7f7f7" : "white",
            }}
        >
            <StyledStack>
                <SheetDetails>
                    {timesheetDates.start.toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                    })}
                </SheetDetails>
                <SheetDetails>
                    {timesheetDates.start.toLocaleTimeString().slice(0, -3)} -{" "}
                    {timesheetDates.end.toLocaleTimeString().slice(0, -3)}
                </SheetDetails>
                <SheetDetails>
                    <TimeSheetPeriodLabel
                        period={timesheet.period ?? "unknown"}
                    />
                </SheetDetails>
                <SheetDetails>
                    <SheetDetails>
                        {calculateWorkingHours(
                            timesheet.startDate,
                            timesheet.endDate,
                            true
                        )}
                    </SheetDetails>
                </SheetDetails>
                <SheetDetails>
                    {mode === "professional"
                        ? timesheet.cost
                        : timesheet.billable}
                    €
                </SheetDetails>
                <Typography
                    justifySelf="center"
                    sx={{
                        backgroundColor: getApproved(t, timesheet.status)
                            .backgroundColor,
                        display: "flex",
                        alignItems: "center",
                        color: "#FAF9F6",
                        justifyContent: "center",
                        borderRadius: "5px",
                        fontSize: "9px",
                        flex: "1",
                        "@media(width >= 768px)": {
                            fontSize: "12px",
                        },
                    }}
                >
                    {getApproved(t, timesheet.status).label}
                </Typography>
                <SheetDetails>
                    {mode === "professional" ? (
                        <EditButton
                            onClick={() => onEdit?.(timesheet.id)}
                            disabled={timesheet.status === "approved"}
                        >
                            <EditNotifications fontSize="small" />
                        </EditButton>
                    ) : (
                        <ApproveTimeSheet
                            timesheet={timesheet}
                            timesheetId={timesheet.id}
                            missionId={missionId || ""}
                            disabled={timesheet.status === "approved"}
                        />
                    )}
                </SheetDetails>
            </StyledStack>
        </SheetBox>
    );
};

const SheetBox = styled(Box)({
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    marginTop: "0.25rem",
});

const SheetDetails = styled(Typography)({
    fontSize: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    padding: "1px",
    "@media(width >= 768px)": {
        fontSize: "14px",
    },
});

const StyledStack = styled(Stack)({
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1",
});

const EditButton = styled(Button)({
    margin: 0,
    width: "25px !important",
    minWidth: "25px !important",
    padding: "2px",
});
