import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SearchAnimation from "../../assets/SearchAnimation.gif";
import { useTranslation } from "react-i18next";

const LoadingAnimation: React.FC = () => {
    const [messageIndex, setMessageIndex] = useState(0);

    const { t } = useTranslation();

    const messages = [
        t("loading.analyze.message"),
        t("loading.selection.message"),
        t("loading.final.message"),
        t("loading.done.message"),
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 1000);

        return () => clearInterval(interval);
    }, [messages.length]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100dvh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "2rem",
                alignItems: "center",
                margin: "2rem 0",
                padding: "2rem",
            }}
        >
            <img
                src={SearchAnimation}
                alt="Loading..."
                style={{ width: "250px" }}
            />
            <Typography
                sx={{ textAlign: "center", fontSize: "1.1rem", color: "gray" }}
            >
                {messages[messageIndex]}
            </Typography>
        </Box>
    );
};

export default LoadingAnimation;
