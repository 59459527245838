import styled, { StyledComponent } from "@emotion/styled";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { TimeSheetPeriod } from "../types";

export interface TimeSheetPeriodLabelProps {
    period: TimeSheetPeriod;
}

export const TimeSheetPeriodLabel = ({ period }: TimeSheetPeriodLabelProps) => {
    const { t } = useTranslation();

    const Label = styledComponents[period];

    return <Label>{t(`missions.timesheet.period.${period}`)}</Label>;
};

const Label = styled("span")({
    padding: "2px 4px",
    borderRadius: "4px",
    border: "1px solid black",
    fontWeight: "bold",
    fontSize: "0.8rem",
    width: "5rem",
});

const styledComponents: {
    [key in TimeSheetPeriod]: StyledComponent<ComponentProps<typeof Label>>;
} = {
    day: styled(Label)({
        color: "#e7b50c",
        backgroundColor: "#fff9c4",
        borderColor: "#e7b50c",
    }),
    night: styled(Label)({
        color: "#1976d2",
        backgroundColor: "#e3f2fd",
        borderColor: "#1976d2",
    }),
    mixed: styled(Label)({
        color: "#ff9800",
        backgroundColor: "#fff3e0",
        borderColor: "#ff9800",
    }),
    unknown: styled(Label)({
        color: "#9e9e9e",
        backgroundColor: "#f5f5f5",
        borderColor: "#9e9e9e",
    }),
};
