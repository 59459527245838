import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    CircularProgress,
    styled,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import * as React from "react";
import { Cities, CitiesFromGovernment } from "../App";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { GetTranslatedProfessions } from "../utils/AvailableJobsUtils";
import "./SearchComponent.css";
import { getDisponibilities } from "./redux/searchAction";
import { useTranslation } from "react-i18next";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import { UserStructureResponse } from "../structures/model/StructureModel";
import { RootState } from "../common/redux/store";
import { Departements, DepartementsFromGovernment } from "../App";
import dayjs from "dayjs";
import { useState } from "react";

const _filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 100,
    stringify: (Cities: CitiesFromGovernment) => Cities.nom,
});

export const SearchComponent = () => {
    const [alignmentJour, setAlignmentJour] = useState<string | null>(null);
    const [alignmentSemaine, setAlignmentSemaine] = useState<string | null>(
        null
    );
    const [dayOpen, setDayOpen] = useState(false);
    const [startDateValue, setStartDateValue] =
        React.useState<dayjs.Dayjs | null>(null);
    const [endDateValue, setEndDateValue] = useState<dayjs.Dayjs | null>(null);
    const [weekOpen, setWeekOpen] = useState(false);
    const [ville, setVille] = useState<string>("");
    const [profession, setProfession] = useState<any>(null);
    const [isAutocompleteVilleOpen, setIsAutocompleteVilleOpen] =
        React.useState<boolean>(false);
    const [isAutocompleteDepartementsOpen, setIsAutocompleteDepartementsOpen] =
        React.useState<boolean>(false);
    const [departement, setDepartement] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const translatedProfessions = GetTranslatedProfessions();

    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const handleAlignmentJour = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentJour(newAlignment);
        }
    };

    const handleAlignmentSemaine = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentSemaine(newAlignment);
        }
    };

    const changeDatePicker = (e: dayjs.Dayjs | null, type: string) => {
        if (e) {
            if (type === "startdate") {
                setStartDateValue(e);
            } else if (type === "enddate") {
                setEndDateValue(e);
            }
        }
    };

    const filterOptions = React.useCallback((options: any, state: any) => {
        const results = _filterOptions(options, state);
        return results;
    }, []);

    const submitResearch = async (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        let params = "&order[createdAt]=asc";

        if (profession) {
            params += "&account.profession=" + profession;
        }
        if (ville) {
            params += "&ville=" + ville;
        }
        if (departement) {
            params += "&departement=" + departement;
        }
        if (alignmentJour && alignmentJour !== "indifferent") {
            params += "&selection=" + alignmentJour;
        } else if (
            !alignmentJour &&
            alignmentSemaine &&
            alignmentSemaine !== "indifferent"
        ) {
            params += "&selection=" + alignmentSemaine;
        }
        if (
            alignmentSemaine &&
            alignmentJour &&
            alignmentSemaine !== "indifferent"
        ) {
            params += "," + alignmentSemaine;
        }

        if (startDateValue) {
            params +=
                "&dateFin[after]=" +
                startDateValue.format("YYYY-MM-DDTHH:mm:ss");
        }

        if (endDateValue) {
            params +=
                "&dateDemarrage[before]=" +
                endDateValue.format("YYYY-MM-DDTHH:mm:ss");
        }

        try {
            await dispatch(getDisponibilities(params));
        } catch (error) {
            console.error("Error in submitResearch:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const departmentOption = createFilterOptions({
        matchFrom: "start",
        limit: 105,
        stringify: (Departements: DepartementsFromGovernment) =>
            Departements.nom,
    });

    return (
        <Stack
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: 0,
                margin: 0,
                width: "100%",
                alignItems: "center",
                color: "#FFFFF0",
                backgroundColor: "#FF8A80",
            }}
        >
            <Grid item sx={{ mb: 3, mt: 6 }}>
                {userSelector.id && userSelector.societe ? (
                    <Typography
                        sx={{
                            gridArea: "title",
                            fontSize: "3ex",
                        }}
                    >
                        {t("language.addPersonnel")}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            gridArea: "title",
                            fontSize: "3ex",
                        }}
                    >
                        {t("language.addStructure")}
                    </Typography>
                )}
            </Grid>
            <FormContainer>
                <InputsContainer isStructure={false}>
                    {userSelector.id && userSelector.societe ? (
                        <Grid
                            item
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "5px",
                                height: "fit-content",
                                width: "160px",
                                gridArea: "profession",

                                "@media(width >= 1200px)": {
                                    width: "200px",
                                },

                                "@media(width >= 1900px)": {
                                    width: "280px",
                                },
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                id="profession"
                                onInputChange={(event, newInputValue) => {
                                    setProfession(newInputValue);
                                }}
                                options={translatedProfessions.sort()}
                                sx={{
                                    borderRadius: "5px",
                                    "& fieldset": {
                                        border: "none",
                                        width: "160px",
                                    },

                                    "@media(width >= 1200px)": {
                                        width: "200px",
                                    },

                                    "@media(width >= 1900px)": {
                                        width: "280px",
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={t(
                                            "professions:Profession"
                                        )}
                                        sx={{ fontSize: "1ex" }}
                                    />
                                )}
                            />
                        </Grid>
                    ) : null}
                    {userSelector.id && userSelector.societe ? (
                        <Grid
                            item
                            sx={{
                                gridArea: "ville",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                width: "160px",
                                height: "fit-content",

                                "@media(width >= 1200px)": {
                                    width: "200px",
                                },

                                "@media(width >= 1900px)": {
                                    width: "280px",
                                },
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box"
                                onInputChange={(event, newInputValue) => {
                                    setVille(newInputValue);
                                    if (newInputValue.length >= 2)
                                        setIsAutocompleteVilleOpen(true);
                                    if (event.type === "click")
                                        setIsAutocompleteVilleOpen(false);
                                }}
                                noOptionsText={t("search.suggestion")}
                                onBlur={() => setIsAutocompleteVilleOpen(false)}
                                open={isAutocompleteVilleOpen}
                                includeInputInList
                                getOptionLabel={(option) => option.nom}
                                filterSelectedOptions
                                filterOptions={filterOptions}
                                options={Cities}
                                sx={{
                                    borderRadius: "5px",
                                    "& fieldset": { border: "none" },
                                    width: "160px",

                                    "@media(width >= 1200px)": {
                                        width: "200px",
                                    },

                                    "@media(width >= 1900px)": {
                                        width: "280px",
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="ville"
                                        id="ville"
                                        placeholder={t("search.city")}
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.code}>
                                            {option.nom}
                                        </li>
                                    );
                                }}
                            />
                        </Grid>
                    ) : (
                        <Stack sx={{ flexDirection: "row", gap: "1rem" }}>
                            <Autocomplete
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    width: "330px",
                                    height: "fit-content",
                                    color: "#3b3c36",
                                    "& fieldset": { border: "none" },
                                    "@media(width >= 1200px)": {
                                        width: "280px",
                                    },
                                    "@media(width >= 1900px)": {
                                        width: "280px",
                                    },
                                }}
                                disablePortal
                                id="combo-box"
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue.length >= 1)
                                        setIsAutocompleteDepartementsOpen(true);
                                }}
                                onChange={(event, value) => {
                                    if (value) {
                                        setDepartement(value.nom);
                                        setIsAutocompleteDepartementsOpen(
                                            false
                                        );
                                    }
                                }}
                                noOptionsText={"Aucune proposition"}
                                open={isAutocompleteDepartementsOpen}
                                onFocus={() => {
                                    setIsAutocompleteDepartementsOpen(true);
                                }}
                                onBlur={() =>
                                    setIsAutocompleteDepartementsOpen(false)
                                }
                                includeInputInList
                                getOptionLabel={(option) => option.nom}
                                filterSelectedOptions
                                filterOptions={departmentOption}
                                options={Departements}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="Departements"
                                        id="Departements"
                                        placeholder={t("search.departements")}
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.code}>
                                            {option.nom}
                                        </li>
                                    );
                                }}
                            />
                        </Stack>
                    )}
                </InputsContainer>

                <InputsContainer isStructure={!userSelector.societe}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                    >
                        <StyledDatePicker
                            disablePast
                            open={dayOpen}
                            value={startDateValue}
                            onOpen={() => setDayOpen(true)}
                            onClose={() => setDayOpen(false)}
                            onChange={(newValue) => {
                                if (dayjs.isDayjs(newValue)) {
                                    changeDatePicker(newValue, "startdate");
                                }
                            }}
                            slotProps={{
                                textField: {
                                    onClick: () => setDayOpen(true),
                                    value: startDateValue,
                                    label: !startDateValue
                                        ? t("select.date.start")
                                        : null,
                                },
                            }}
                            slots={{
                                day: (startDateValue) => (
                                    <PickersDay
                                        {...startDateValue}
                                        color="primary"
                                    />
                                ),
                            }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider
                        adapterLocale="fr"
                        dateAdapter={AdapterDayjs}
                    >
                        <StyledDatePicker
                            disablePast
                            open={weekOpen}
                            value={endDateValue}
                            onOpen={() => setWeekOpen(true)}
                            onClose={() => setWeekOpen(false)}
                            onChange={(endDateValue) => {
                                if (dayjs.isDayjs(endDateValue)) {
                                    changeDatePicker(endDateValue, "enddate");
                                }
                            }}
                            slotProps={{
                                textField: {
                                    onClick: () => setWeekOpen(true),
                                    value: endDateValue,
                                    label: !endDateValue
                                        ? t("select.date.end")
                                        : null,
                                },
                            }}
                            slots={{
                                day: (endDateValue) => (
                                    <PickersDay
                                        {...endDateValue}
                                        color="primary"
                                    />
                                ),
                            }}
                        />
                    </LocalizationProvider>
                </InputsContainer>
                <Stack
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "2rem",
                        width: "fit-content",

                        "@media(width >= 768px)": {
                            flexDirection: "row",
                        },
                    }}
                >
                    <ToggleContainer>
                        <ButtonGroup
                            value={alignmentJour}
                            exclusive
                            id="Jour"
                            onChange={handleAlignmentJour}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="jour"
                                aria-label="left aligned"
                                sx={{
                                    borderRadius: "5px",
                                    padding: "0 1.6rem 0 1.8rem",
                                }}
                            >
                                {t("select.day")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="nuit"
                                aria-label="centered"
                                sx={{
                                    padding: "0 1.65rem 0 2rem",
                                }}
                            >
                                {t("select.night")}
                            </MyToggleButton>

                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>
                        <ButtonGroup
                            value={alignmentSemaine}
                            exclusive
                            id="Semaine"
                            onChange={handleAlignmentSemaine}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="semaines"
                                aria-label="left aligned"
                            >
                                {t("select.period.weekend")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="weekend"
                                aria-label="centered"
                            >
                                Week-End
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>
                    </ToggleContainer>
                    <Submit type="submit" onClick={submitResearch}>
                        {isSubmitting ? (
                            <CircularProgress color="inherit" size={24} />
                        ) : (
                            <TaskAltSharpIcon
                                fontSize="small"
                                sx={{ width: 58, height: 58 }}
                            />
                        )}
                    </Submit>
                </Stack>
            </FormContainer>
        </Stack>
    );
};

const MyToggleButton = styled(ToggleButton)({
    background: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 3px 3px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    borderRadius: "5px",
    fontSize: "13px",
    width: "113px",

    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#36454F",
        background:
            "linear-gradient(to right top, #c1e1c1, #c2e1c3, #c3e1c5, #c5e1c7, #c6e1c9, #c6e1c9, #c6e1c9, #c6e1c9, #c5e1c7, #c3e1c5, #c2e1c3, #c1e1c1)",
    },
    "&:hover": { backgroundColor: "#E5E4E2" },

    "@media (width >= 765px)": {
        width: "95px",
    },

    "@media(width >= 1200px)": {
        width: "120px",
    },
});

const FormContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    paddingBottom: "1rem",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "@media(width >= 768px)": {
        flexDirection: "row",
    },

    "@media(width >= 1200px)": {
        gap: "3rem",
    },

    "@media(width >= 1899px)": {
        gap: "4rem",
    },
});

const StyledDatePicker = styled(DatePicker)({
    width: "160px",
    backgroundColor: "white",
    borderRadius: "5px",

    "@media(width >= 1200px)": {
        width: "200px",
    },

    "@media(width >= 1900px)": {
        width: "280px",
    },
});

const InputsContainer = styled(Stack)<{ isStructure: boolean }>(
    ({ isStructure }) => ({
        gap: "1rem",
        flexDirection: isStructure ? "column" : "row",

        "@media(width >= 768px)": {
            flexDirection: isStructure ? "row" : "column",
        },
    })
);

const ToggleContainer = styled(Stack)({
    gap: "1rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
});

const ButtonGroup = styled(ToggleButtonGroup)({
    height: "55px",
    width: "335px",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 7px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 768px)": {
        width: "280px",
        height: "45px",
    },

    "@media(width >= 1200px)": {
        width: "350px",
    },
});

const Submit = styled(Button)({
    borderRadius: "50%",
    width: "50px",
    height: "65px",
    padding: "0.5rem",
    alignSelf: "center",
    color: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    background:
        "linear-gradient(to bottom, #fdaaaa, #fda2a2, #fc9999, #fc9191, #fb8888, #f98282, #f87d7d, #f67777, #f47473, #f27070, #f06d6c, #ee6969)",
});
