import { Stack, List, Typography, Divider, Box, Paper } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { formatDate } from "../utils/utils";
import { getMissionById } from "../../common/redux/missionActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../common/redux/store";
import { useTranslation } from "react-i18next";
import { ProfessionnelMission } from "../../missions/model/professionnelTypes";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

interface MissionProfessionnelProps {
    filteredMissions: ProfessionnelMission[];
    handleClick: (item: ProfessionnelMission) => void;
    sortOrder: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const MissionProfessionnel: React.FC<MissionProfessionnelProps> = ({
    filteredMissions,
    handleClick,
    sortOrder,
}) => {
    const { t } = useTranslation();

    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const getStatusStyle = (status: string) => {
        switch (status) {
            case t("mission.status.progress"):
                return { backgroundColor: "#F28C28" };
            case t("mission.status.past"):
                return { backgroundColor: "#008200" };
            case t("mission.status.upcoming"):
                return { backgroundColor: "#818589" };
            default:
                return { backgroundColor: "#899499" };
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(getMissionById({ id, role: "ROLE_PROFESSIONNEL" }));
        }
    }, [id, dispatch]);

    return (
        <ListContainer>
            {filteredMissions.map((mission) => {
                return (
                    <StyledPaper
                        key={mission.id.toString()}
                        onClick={() => handleClick(mission)}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "start",
                                width: "100%",
                                marginBottom: "1rem",
                            }}
                        >
                            <Reference variant="h6">{mission.ref}</Reference>
                            <Status
                                sx={{
                                    backgroundColor: getStatusStyle(
                                        mission.status
                                    ),
                                }}
                            >
                                {mission.status}
                            </Status>
                        </Box>
                        <Structure variant="body1">
                            {mission.structure.societe}
                        </Structure>
                        <Profession>
                            <MedicalServicesIcon />
                            {mission.job}
                        </Profession>
                        <Divider sx={{ width: "100%", marginY: 1 }} />
                        <Stack
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "30px",
                                margin: "1.5rem 0 1.5rem 0",
                            }}
                        >
                            <Stack
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <Typography variant="body2">
                                    {t("select.date.start")}
                                </Typography>
                                <Typography variant="body2">
                                    {formatDate(mission.startDate, true)}
                                </Typography>
                            </Stack>
                            <Stack
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <Typography variant="body2">
                                    {t("select.date.end")}
                                </Typography>
                                <Typography variant="body2">
                                    {formatDate(mission.endDate, true)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </StyledPaper>
                );
            })}
        </ListContainer>
    );
};
export default MissionProfessionnel;

const ListContainer = styled(List)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    height: "auto",

    "@media(width >= 768px)": {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "start",
    },
});

const StyledPaper = styled(Paper)({
    backgroundColor: "#f7f7f7",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "5px",
    width: "90%",
    cursor: "context-menu",
    "@media(width >= 768px)": {
        width: "45%",
    },
    "@media(width >= 990px)": {
        width: "40%",
    },
    "@media(width >= 1990px)": {
        width: "30%",
    },
});

const Reference = styled(Typography)({
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "5px",
    color: "#555555",
});

const Structure = styled(Typography)({
    fontSize: "16px",
    color: "#E53935",
    fontFamily: "Montserrat",
    width: "100%",
    textAlign: "center",
    "@media(width >= 990px)": {
        fontSize: "18px",
    },
});

const Status = styled(Typography)({
    padding: "5px",
    borderRadius: "0 5px 0 20px",
    color: "white",
    fontSize: "12px",
    textAlign: "center",
    width: "80px",
});

const Profession = styled(Typography)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "1rem",
    fontSize: "14px",
    "@media(width >= 990px)": {
        fontSize: "16px",
    },
});
