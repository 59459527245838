import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteAPI, api } from "../../common/service/apiService";
import { storageService } from "../../common/service/storageService";

interface StructureProfile {
    societe: string;
    siret: string;
    email: string;
    plainPassword?: string;
    telephone: string;
    profileImage?: string;
    description: string;
}

interface ProfessionnelProfile {
    nom: string;
    prenom: string;
    email: string;
    plainPassword?: string;
    telephone: string;
    profileImage?: string;
    description: string;
    profession: string[];
    departements?: string[];
}

export const postUpdateProfil = createAsyncThunk<any, StructureProfile>(
    "profil/postUpdate",
    async (data, thunkAPI) => {
        try {
            const response = await api(
                RouteAPI.STRUCTURES + "/" + storageService.getIdWithToken(),
                "PATCH",
                data
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseMessage>
            );
        }
    }
);

export const professionnelUpdateProfil = createAsyncThunk<
    any,
    ProfessionnelProfile
>("profil/postUpdateProfessionnel", async (data, thunkAPI) => {
    try {
        const response = await api(
            RouteAPI.PROFESSIONNELS + "/" + storageService.getIdWithToken(),
            "PATCH",
            data
        );
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
});
